<template>
  <div class="popup-detail">
    <el-dialog
      title="付款单申请"
      width="85%"
      :close-on-click-modal="false"
      :visible.sync="visible">
      <el-form :model="dataForm"
               :rules="dataRule"
               ref="dataForm"
               label-width="110px"
               size="small">
        <div style="margin-top: -5px;margin-bottom:8px;">
          <label class="title"><span class="separate">|</span>付款单信息</label>
        </div>
        <div class="infoBox">
          <el-row :gutter="50">
            <el-col :span="8">
              <el-form-item label="付款单类型" prop="payOrderType">
                <el-select class="item-choose"
                           v-model="dataForm.payOrderType"
                           style="width: 100%;"
                           filterable
                           :disabled="isDetail"
                           placeholder="请选择">
                  <el-option
                    v-for="item in payRequestTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="付款依据来源" prop="payBasisSource">
                <el-select class="item-choose"
                           v-model="dataForm.payBasisSource"
                           style="width: 100%;"
                           filterable
                           :disabled="isDetail || autoOpen"
                           @change="clearRequestPayAmount"
                           placeholder="请选择">
                  <el-option
                    v-for="item in payBasisSourceList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="付款对象" prop="payObject">
                <el-select class="item-choose"
                           v-model="dataForm.payObject"
                           style="width: 100%;"
                           filterable
                           :disabled="isDetail || autoOpen"
                           placeholder="请选择">
                  <el-option
                    v-for="item in payObjectList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="50">
            <el-col :span="8">
              <el-form-item label="付款申请金额" prop="requestPayAmount">
                <el-input v-model="dataForm.requestPayAmount"
                          placeholder="费用(元)"
                          maxlength="10"
                          :disabled="dataForm.payBasisSource === 1 || isDetail">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="收款供应商" prop="motorcadeId">
                <el-select class="item-choose"
                           v-model="dataForm.motorcadeId"
                           style="width: 100%;"
                           filterable
                           :disabled="isDetail || autoOpen"
                           @change="findDriverList(null,null)"
                           placeholder="请选择">
                  <el-option
                    v-for="item in motorcadeList"
                    :key="item.id"
                    :label="item.simpleName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="收款人姓名" prop="driverId">
                <el-select class="item-choose"
                           v-model="dataForm.driverId"
                           style="width: 100%;"
                           filterable
                           :disabled="isDetail || disableDriver"
                           @change="changeDriver"
                           placeholder="请选择">
                  <el-option
                    v-for="item in driverList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="24">
              <el-form-item label="付款说明" prop="remark">
                <el-input type="textarea"
                          :rows="2"
                          v-model="dataForm.remark"
                          :disabled="isDetail"
                          maxlength="100"
                          show-word-limit>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>

      <!--  付款对象是非自营司机显示应付费用科目    -->
      <el-form :model="orderDataForm" ref="orderDataForm" size="small"
               v-if="dataForm.payBasisSource === 1 && dataForm.payObject === 2">
        <div style="margin-top:15px;margin-bottom:8px;">
          <label class="title"><span class="separate">|</span>订单依据</label>
          <el-button style="margin-left: 20px;" round type="primary" v-if="!isDetail"
                     @click="addOrderFree()"
                     size="mini">{{ autoOpen ? '修改' : '添加' }}
          </el-button>
        </div>
        <div class="infoBox infoBox2">
          <el-table :data="orderDataForm.dataList" border
                    size="mini"
                    max-height="500px"
                    :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                    style="width: 100%">
            <el-table-column type="index" label="序号" align="center" width="45"></el-table-column>
            <el-table-column prop="vehiclePlan.orderTakeTime" label="提货时间" align="center" width="120"></el-table-column>
            <el-table-column prop="vehiclePlan.motorcadeName" label="供应商" align="center" width="100" show-overflow-tooltip></el-table-column>
            <el-table-column prop="vehiclePlan.driverName" label="司机姓名" align="center" width="70"></el-table-column>
            <el-table-column prop="vehiclePlan.vehiclePlate" label="司机车牌" align="center" width="80"></el-table-column>
            <el-table-column prop="total" label="申请合计" align="center" min-width="75"
                             class-name="yellow-th"></el-table-column>
            <el-table-column prop="freightFee" label="运费" align="center" min-width="70"></el-table-column>
            <el-table-column prop="entranceFee" label="报关/入仓" align="center" min-width="75"></el-table-column>
            <el-table-column prop="highFee" label="高速费" align="center" min-width="60"></el-table-column>
            <el-table-column prop="weighFee" label="过磅费" align="center" min-width="60"></el-table-column>
            <el-table-column prop="loadFee" label="装卸费" align="center" min-width="60"></el-table-column>
            <el-table-column prop="overtimeFee" label="押夜超时" align="center" min-width="70"></el-table-column>
            <el-table-column prop="takeFee" label="提货费" align="center" min-width="60"></el-table-column>
            <el-table-column prop="addFee" label="加点费" align="center" min-width="60"></el-table-column>
            <el-table-column prop="parkingFee" label="停车费" align="center" min-width="60">
              <template slot-scope="scope">
                {{ scope.row.parkingFee }}
              </template>
            </el-table-column>
            <el-table-column prop="printFee" label="打印费" align="center" min-width="60"></el-table-column>
            <el-table-column prop="maintainFee" label="维修费" align="center" min-width="60"></el-table-column>
            <el-table-column prop="refuelFee" label="现金加油" align="center" min-width="70"></el-table-column>
            <el-table-column prop="passingFee" label="现金过路" align="center" min-width="70"></el-table-column>
            <el-table-column prop="refuelCardFee" label="油卡费" align="center" min-width="70"></el-table-column>
            <el-table-column prop="passingCardFee" label="粤通卡费" align="center" min-width="70"></el-table-column>
            <el-table-column prop="otherFee" label="其他费" align="center" min-width="60"></el-table-column>
            <el-table-column prop="driverBorrowing" label="司机借支" align="center" min-width="80"></el-table-column>
            <el-table-column prop="deductionsFee" label="扣款" align="center" min-width="60"></el-table-column>
            <el-table-column label="操作" align="center" width="50" v-if="!isDetail  && !autoOpen">
              <template slot-scope="scope">
                <el-button type="text" @click="deleteRow(scope.row.id)" size="small">
                  移除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form>

      <!--  付款对象是自营司机显示提成费用池科目    -->
      <el-form :model="royaltyDataForm" ref="royaltyDataForm" size="small"
               v-if="dataForm.payBasisSource === 1 && dataForm.payObject === 1">
        <div style="margin-top:15px;margin-bottom:8px;">
          <label class="title"><span class="separate">|</span>订单依据</label>
          <el-button style="margin-left: 20px;" round type="primary" v-if="!isDetail"
                     @click="addOrderFree()"
                     size="mini">{{ autoOpen ? '修改' : '添加' }}
          </el-button>
        </div>
        <div class="infoBox infoBox2">
          <el-table :data="royaltyDataForm.dataList" border
                    size="mini"
                    max-height="500px"
                    :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                    style="width: 100%">
            <el-table-column type="index" label="序号" align="center" width="45"></el-table-column>
            <el-table-column prop="vehiclePlan.orderTakeTime" label="提货时间" align="center" width="120"></el-table-column>
            <el-table-column prop="vehiclePlan.vehiclePlate" label="司机车牌" align="center" width="80"></el-table-column>
            <el-table-column prop="vehiclePlan.motorcadeName" label="供应商" align="center" width="110"></el-table-column>
            <el-table-column prop="vehiclePlan.driverName" label="司机姓名" align="center" width="70"></el-table-column>
            <el-table-column prop="total" label="申请合计" align="center" min-width="75"
                             class-name="yellow-th"></el-table-column>
            <el-table-column prop="royaltyFee" label="司机提成" align="center" min-width="70"></el-table-column>
            <!--            <el-table-column prop="overtimeFee" label="押夜超时" align="center" min-width="70"></el-table-column>-->
            <!--            <el-table-column prop="addFee" label="加点费" align="center" min-width="60"></el-table-column>-->
            <el-table-column prop="loadFee" label="装卸费" align="center" min-width="60"></el-table-column>
            <el-table-column prop="extraFee" label="加班费" align="center" min-width="60"></el-table-column>
            <el-table-column prop="stayFee" label="住宿费" align="center" min-width="60"></el-table-column>
            <el-table-column prop="baseFee" label="基本工资" align="center" min-width="80"></el-table-column>
            <el-table-column prop="meritsFee" label="绩效工资" align="center" min-width="80"></el-table-column>
            <el-table-column prop="appFee" label="使用APP奖励" align="center" min-width="100"></el-table-column>
            <el-table-column prop="other1Fee" label="租房费" align="center" min-width="80"></el-table-column>
            <el-table-column prop="other2Fee" label="油耗奖励" align="center" min-width="80"></el-table-column>
            <el-table-column prop="safetyRewards" label="安全奖" align="center" min-width="80"></el-table-column>
            <el-table-column prop="driverBorrowing" label="司机借支" align="center" min-width="80"></el-table-column>
            <el-table-column prop="deductionsFee" label="扣社保/公积金" align="center" min-width="60"></el-table-column>

            <el-table-column prop="accumulationFund" label="请假扣款" align="center" min-width="60"></el-table-column>
            <el-table-column prop="safetyAward" label="扣减安全奖" align="center" min-width="60"></el-table-column>
            <el-table-column prop="oilConsumption" label="扣减违章/油耗" align="center" min-width="60"></el-table-column>
            <el-table-column prop="hydropower" label="分摊水电费" align="center" min-width="60"></el-table-column>
            <el-table-column prop="personalIncomeTax" label="个税" align="center" min-width="60"></el-table-column>
            <el-table-column prop="otherExpenses" label="其他费用" align="center" min-width="60"></el-table-column>

            <el-table-column label="操作" align="center" width="60" v-if="!isDetail  && !autoOpen">
              <template slot-scope="scope">
                <el-button type="text" @click="deleteRow(scope.row.id)" size="small">
                  移除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form>

      <!--  付款对象是业务部显示提成费用池科目    -->
      <el-form :model="royaltySaleDataForm" ref="royaltySaleDataForm" size="small"
               v-if="dataForm.payBasisSource === 1 && dataForm.payObject === 3">
        <div style="margin-top:15px;margin-bottom:8px;">
          <label class="title"><span class="separate">|</span>订单依据</label>
          <el-button style="margin-left: 20px;" round type="primary" v-if="!isDetail"
                     @click="addOrderFree()"
                     size="mini">{{ autoOpen ? '修改' : '添加' }}
          </el-button>
        </div>
        <div class="infoBox infoBox2">
          <el-table :data="royaltySaleDataForm.dataList" border
                    size="mini"
                    max-height="500px"
                    :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                    style="width: 100%">
            <el-table-column type="index" label="序号" align="center" width="45"></el-table-column>
            <el-table-column prop="vehiclePlan.orderTakeTime" label="提货时间" align="center" width="120"></el-table-column>
            <el-table-column prop="vehiclePlan.vehiclePlate" label="司机车牌" align="center" width="80"></el-table-column>
            <el-table-column prop="vehiclePlan.motorcadeName" label="供应商" align="center" width="110"></el-table-column>
            <el-table-column prop="vehiclePlan.driverName" label="司机姓名" align="center" width="70"></el-table-column>
            <el-table-column prop="total" label="申请合计" align="center" min-width="75"
                             class-name="yellow-th"></el-table-column>
            <el-table-column prop="saleRoyaltyFee" label="提成" align="center" min-width="70"></el-table-column>
            <!--            <el-table-column prop="overtimeFee" label="押夜超时" align="center" min-width="70"></el-table-column>-->
            <!--            <el-table-column prop="addFee" label="加点费" align="center" min-width="60"></el-table-column>-->
            <el-table-column prop="loadFee" label="装卸费" align="center" min-width="60"></el-table-column>
            <el-table-column prop="extraFee" label="加班费" align="center" min-width="60"></el-table-column>
            <el-table-column prop="stayFee" label="住宿费" align="center" min-width="60"></el-table-column>
            <el-table-column prop="saleBaseFee" label="基本工资" align="center" min-width="80"></el-table-column>
            <el-table-column prop="meritsFee" label="绩效工资" align="center" min-width="80"></el-table-column>
            <el-table-column prop="appFee" label="使用APP奖励" align="center" min-width="100"></el-table-column>
            <el-table-column prop="other1Fee" label="租房费" align="center" min-width="80"></el-table-column>
            <el-table-column prop="other2Fee" label="油耗奖励" align="center" min-width="80"></el-table-column>
            <el-table-column prop="safetyRewards" label="安全奖" align="center" min-width="80"></el-table-column>
            <el-table-column prop="borrowing" label="借支" align="center" min-width="80"></el-table-column>
            <el-table-column prop="deductionsFee" label="扣社保" align="center" min-width="60"></el-table-column>
            <el-table-column prop="accumulationFund" label="公积金" align="center" min-width="60"></el-table-column>
            <el-table-column prop="safetyAward" label="扣减安全奖" align="center" min-width="60"></el-table-column>
            <el-table-column prop="oilConsumption" label="扣减违章/油耗" align="center" min-width="60"></el-table-column>
            <el-table-column prop="hydropower" label="分摊水电费" align="center" min-width="60"></el-table-column>
            <el-table-column prop="personalIncomeTax" label="个税" align="center" min-width="60"></el-table-column>
            <el-table-column prop="otherExpenses" label="其他费用" align="center" min-width="60"></el-table-column>

            <el-table-column label="操作" align="center" width="60" v-if="!isDetail  && !autoOpen">
              <template slot-scope="scope">
                <el-button type="text" @click="deleteRow(scope.row.id)" size="small">
                  移除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form>

      <!--  付款对象是业务经理显示提成费用池科目    -->
      <el-form :model="royaltySaleAdminDataForm" ref="royaltySaleAdminDataForm" size="small"
               v-if="dataForm.payBasisSource === 1 && dataForm.payObject === 5">
        <div style="margin-top:15px;margin-bottom:8px;">
          <label class="title"><span class="separate">|</span>订单依据</label>
          <el-button style="margin-left: 20px;" round type="primary" v-if="!isDetail"
                     @click="addOrderFree()"
                     size="mini">{{ autoOpen ? '修改' : '添加' }}
          </el-button>
        </div>
        <div class="infoBox infoBox2">
          <el-table :data="royaltySaleAdminDataForm.dataList" border
                    size="mini"
                    max-height="500px"
                    :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                    style="width: 100%">
            <el-table-column type="index" label="序号" align="center" width="45"></el-table-column>
            <el-table-column prop="vehiclePlan.orderTakeTime" label="提货时间" align="center" width="120"></el-table-column>
            <el-table-column prop="vehiclePlan.vehiclePlate" label="司机车牌" align="center" width="80"></el-table-column>
            <el-table-column prop="vehiclePlan.motorcadeName" label="供应商" align="center" width="110"></el-table-column>
            <el-table-column prop="vehiclePlan.driverName" label="司机姓名" align="center" width="70"></el-table-column>
            <el-table-column prop="total" label="申请合计" align="center" min-width="75"
                             class-name="yellow-th"></el-table-column>
            <el-table-column prop="saleRoyaltyFee" label="提成" align="center" min-width="70"></el-table-column>
            <!--            <el-table-column prop="overtimeFee" label="押夜超时" align="center" min-width="70"></el-table-column>-->
            <!--            <el-table-column prop="addFee" label="加点费" align="center" min-width="60"></el-table-column>-->
            <el-table-column prop="loadFee" label="装卸费" align="center" min-width="60"></el-table-column>
            <el-table-column prop="extraFee" label="加班费" align="center" min-width="60"></el-table-column>
            <el-table-column prop="stayFee" label="住宿费" align="center" min-width="60"></el-table-column>
            <el-table-column prop="saleBaseFee" label="基本工资" align="center" min-width="80"></el-table-column>
            <el-table-column prop="meritsFee" label="绩效工资" align="center" min-width="80"></el-table-column>
            <el-table-column prop="appFee" label="使用APP奖励" align="center" min-width="100"></el-table-column>
            <el-table-column prop="other1Fee" label="租房费" align="center" min-width="80"></el-table-column>
            <el-table-column prop="other2Fee" label="油耗奖励" align="center" min-width="80"></el-table-column>
            <el-table-column prop="safetyRewards" label="安全奖" align="center" min-width="80"></el-table-column>
            <el-table-column prop="borrowing" label="借支" align="center" min-width="80"></el-table-column>
            <el-table-column prop="deductionsFee" label="扣社保" align="center" min-width="60"></el-table-column>
            <el-table-column prop="accumulationFund" label="公积金" align="center" min-width="60"></el-table-column>
            <el-table-column prop="safetyAward" label="扣减安全奖" align="center" min-width="60"></el-table-column>
            <el-table-column prop="oilConsumption" label="扣减违章/油耗" align="center" min-width="60"></el-table-column>
            <el-table-column prop="hydropower" label="分摊水电费" align="center" min-width="60"></el-table-column>
            <el-table-column prop="personalIncomeTax" label="个税" align="center" min-width="60"></el-table-column>
            <el-table-column prop="otherExpenses" label="其他费用" align="center" min-width="60"></el-table-column>

            <el-table-column label="操作" align="center" width="60" v-if="!isDetail  && !autoOpen">
              <template slot-scope="scope">
                <el-button type="text" @click="deleteRow(scope.row.id)" size="small">
                  移除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form>

      <!--  付款对象是客服部显示提成费用池科目    -->
      <el-form :model="royaltyCareDataForm" ref="royaltyCareDataForm" size="small"
               v-if="dataForm.payBasisSource === 1 && dataForm.payObject === 4">
        <div style="margin-top:15px;margin-bottom:8px;">
          <label class="title"><span class="separate">|</span>订单依据</label>
          <el-button style="margin-left: 20px;" round type="primary" v-if="!isDetail"
                     @click="addOrderFree()"
                     size="mini">{{ autoOpen ? '修改' : '添加' }}
          </el-button>
        </div>
        <div class="infoBox infoBox2">
          <el-table :data="royaltyCareDataForm.dataList" border
                    size="mini"
                    max-height="500px"
                    :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                    style="width: 100%">
            <el-table-column type="index" label="序号" align="center" width="45"></el-table-column>
            <el-table-column prop="vehiclePlan.orderTakeTime" label="提货时间" align="center" width="120"></el-table-column>
            <el-table-column prop="vehiclePlan.vehiclePlate" label="司机车牌" align="center" width="80"></el-table-column>
            <el-table-column prop="vehiclePlan.motorcadeName" label="供应商" align="center" width="110"></el-table-column>
            <el-table-column prop="vehiclePlan.driverName" label="司机姓名" align="center" width="70"></el-table-column>
            <el-table-column prop="total" label="申请合计" align="center" min-width="75"
                             class-name="yellow-th"></el-table-column>
            <el-table-column prop="careRoyaltyFee" label="提成" align="center" min-width="70"></el-table-column>
            <!--            <el-table-column prop="overtimeFee" label="押夜超时" align="center" min-width="70"></el-table-column>-->
            <!--            <el-table-column prop="addFee" label="加点费" align="center" min-width="60"></el-table-column>-->
            <el-table-column prop="loadFee" label="装卸费" align="center" min-width="60"></el-table-column>
            <el-table-column prop="extraFee" label="加班费" align="center" min-width="60"></el-table-column>
            <el-table-column prop="stayFee" label="住宿费" align="center" min-width="60"></el-table-column>
            <el-table-column prop="careBaseFee" label="基本工资" align="center" min-width="80"></el-table-column>
            <el-table-column prop="meritsFee" label="绩效工资" align="center" min-width="80"></el-table-column>
            <el-table-column prop="appFee" label="使用APP奖励" align="center" min-width="100"></el-table-column>
            <el-table-column prop="other1Fee" label="租房费" align="center" min-width="80"></el-table-column>
            <el-table-column prop="other2Fee" label="油耗奖励" align="center" min-width="80"></el-table-column>
            <el-table-column prop="safetyRewards" label="安全奖" align="center" min-width="80"></el-table-column>
            <el-table-column prop="borrowing" label="借支" align="center" min-width="80"></el-table-column>
            <el-table-column prop="deductionsFee" label="扣社保" align="center" min-width="60"></el-table-column>
            <el-table-column prop="accumulationFund" label="公积金" align="center" min-width="60"></el-table-column>
            <el-table-column prop="safetyAward" label="扣减安全奖" align="center" min-width="60"></el-table-column>
            <el-table-column prop="oilConsumption" label="扣减违章/油耗" align="center" min-width="60"></el-table-column>
            <el-table-column prop="hydropower" label="分摊水电费" align="center" min-width="60"></el-table-column>
            <el-table-column prop="personalIncomeTax" label="个税" align="center" min-width="60"></el-table-column>
            <el-table-column prop="otherExpenses" label="其他费用" align="center" min-width="60"></el-table-column>

            <el-table-column label="操作" align="center" width="60" v-if="!isDetail  && !autoOpen">
              <template slot-scope="scope">
                <el-button type="text" @click="deleteRow(scope.row.id)" size="small">
                  移除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form>

      <!--  付款对象是客服经理显示提成费用池科目    -->
      <el-form :model="royaltyCareAdminDataForm" ref="royaltyCareAdminDataForm" size="small"
               v-if="dataForm.payBasisSource === 1 && dataForm.payObject === 6">
        <div style="margin-top:15px;margin-bottom:8px;">
          <label class="title"><span class="separate">|</span>订单依据</label>
          <el-button style="margin-left: 20px;" round type="primary" v-if="!isDetail"
                     @click="addOrderFree()"
                     size="mini">{{ autoOpen ? '修改' : '添加' }}
          </el-button>
        </div>
        <div class="infoBox infoBox2">
          <el-table :data="royaltyCareAdminDataForm.dataList" border
                    size="mini"
                    max-height="500px"
                    :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                    style="width: 100%">
            <el-table-column type="index" label="序号" align="center" width="45"></el-table-column>
            <el-table-column prop="vehiclePlan.orderTakeTime" label="提货时间" align="center" width="120"></el-table-column>
            <el-table-column prop="vehiclePlan.vehiclePlate" label="司机车牌" align="center" width="80"></el-table-column>
            <el-table-column prop="vehiclePlan.motorcadeName" label="供应商" align="center" width="110"></el-table-column>
            <el-table-column prop="vehiclePlan.driverName" label="司机姓名" align="center" width="70"></el-table-column>
            <el-table-column prop="total" label="申请合计" align="center" min-width="75"
                             class-name="yellow-th"></el-table-column>
            <el-table-column prop="careRoyaltyFee" label="提成" align="center" min-width="70"></el-table-column>
            <!--            <el-table-column prop="overtimeFee" label="押夜超时" align="center" min-width="70"></el-table-column>-->
            <!--            <el-table-column prop="addFee" label="加点费" align="center" min-width="60"></el-table-column>-->
            <el-table-column prop="loadFee" label="装卸费" align="center" min-width="60"></el-table-column>
            <el-table-column prop="extraFee" label="加班费" align="center" min-width="60"></el-table-column>
            <el-table-column prop="stayFee" label="住宿费" align="center" min-width="60"></el-table-column>
            <el-table-column prop="careBaseFee" label="基本工资" align="center" min-width="80"></el-table-column>
            <el-table-column prop="meritsFee" label="绩效工资" align="center" min-width="80"></el-table-column>
            <el-table-column prop="appFee" label="使用APP奖励" align="center" min-width="100"></el-table-column>
            <el-table-column prop="other1Fee" label="租房费" align="center" min-width="80"></el-table-column>
            <el-table-column prop="other2Fee" label="油耗奖励" align="center" min-width="80"></el-table-column>
            <el-table-column prop="safetyRewards" label="安全奖" align="center" min-width="80"></el-table-column>
            <el-table-column prop="borrowing" label="借支" align="center" min-width="80"></el-table-column>
            <el-table-column prop="deductionsFee" label="扣社保" align="center" min-width="60"></el-table-column>
            <el-table-column prop="accumulationFund" label="公积金" align="center" min-width="60"></el-table-column>
            <el-table-column prop="safetyAward" label="扣减安全奖" align="center" min-width="60"></el-table-column>
            <el-table-column prop="oilConsumption" label="扣减违章/油耗" align="center" min-width="60"></el-table-column>
            <el-table-column prop="hydropower" label="分摊水电费" align="center" min-width="60"></el-table-column>
            <el-table-column prop="personalIncomeTax" label="个税" align="center" min-width="60"></el-table-column>
            <el-table-column prop="otherExpenses" label="其他费用" align="center" min-width="60"></el-table-column>

            <el-table-column label="操作" align="center" width="60" v-if="!isDetail  && !autoOpen">
              <template slot-scope="scope">
                <el-button type="text" @click="deleteRow(scope.row.id)" size="small">
                  移除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" v-if="!isDetail" @click="dataFormSubmit()" :disabled="submitButton">确定</el-button>
      </span>
    </el-dialog>

    <add-order-popup v-if="addOrderVisible" ref="addOrderPopup"></add-order-popup>
    <add-royalty-popup v-if="addRoyaltyVisible" ref="addRoyaltyPopup"></add-royalty-popup>
    <add-sale-royalty-popup v-if="addSaleRoyaltyVisible" ref="addSaleRoyaltyPopup"></add-sale-royalty-popup>
    <add-sale-admin-royalty-popup v-if="addSaleAdminRoyaltyVisible" ref="addSaleAdminRoyaltyPopup"></add-sale-admin-royalty-popup>
    <add-care-royalty-popup v-if="addCareRoyaltyVisible" ref="addCareRoyaltyPopup"></add-care-royalty-popup>
    <add-care-admin-royalty-popup v-if="addCareAdminRoyaltyVisible" ref="addCareAdminRoyaltyPopup"></add-care-admin-royalty-popup>
  </div>
</template>

<script>
import AddOrderPopup from './add-order-free-popup'
import AddRoyaltyPopup from './add-royalty-free-popup'
import AddSaleRoyaltyPopup from './add-sale-royalty-free-popup'
import AddSaleAdminRoyaltyPopup from './add-sale-admin-royalty-free-popup'
import AddCareRoyaltyPopup from './add-care-royalty-free-popup'
import AddCareAdminRoyaltyPopup from './add-care-admin-royalty-free-popup'

export default {
  data () {
    return {
      visible: false,
      submitButton: false,
      dataForm: {
        payOrderType: null,
        payBasisSource: null,
        payObject: null,
        requestPayAmount: null,
        motorcadeId: null,
        motorcadeName: null,
        driverId: null,
        driverName: null,
        wxOpenId: null,
        remark: null
      },
      isDetail: false,
      disableDriver: false,
      autoOpen: false,
      costData: {},
      // 应付费用池数据对象
      orderDataForm: {
        dataList: [],
        costMap: new Map()
      },
      // 提成费用池数据对象
      royaltyDataForm: {
        dataList: [],
        costMap: new Map()
      },
      // 业务部提成费用池数据对象
      royaltySaleDataForm: {
        dataList: [],
        costMap: new Map()
      },
      // 业务经理提成费用池数据对象
      royaltySaleAdminDataForm: {
        dataList: [],
        costMap: new Map()
      },
      // 客服部提成费用池数据对象
      royaltyCareDataForm: {
        dataList: [],
        costMap: new Map()
      },
      // 客服经理提成费用池数据对象
      royaltyCareAdminDataForm: {
        dataList: [],
        costMap: new Map()
      },
      addOrderVisible: false,
      addRoyaltyVisible: false,
      addSaleRoyaltyVisible: false,
      addSaleAdminRoyaltyVisible: false,
      addCareRoyaltyVisible: false,
      addCareAdminRoyaltyVisible: false,
      motorcadeList: [],
      driverList: [],
      payRequestTypeList: [
        {
          label: '往来',
          value: 1
        },
        {
          label: '冲账',
          value: 2
        },
        {
          label: '报销',
          value: 3
        },
        {
          label: '业务',
          value: 4
        },
        {
          label: '其他',
          value: 5
        }
      ],
      payBasisSourceList: [
        {
          label: '订单',
          value: 1
        },
        {
          label: '其他',
          value: 2
        }
      ],
      payObjectList: [
        {
          label: '自营司机(提成费用池)',
          value: 1
        },
        {
          label: '非自营司机(应付费用池)',
          value: 2
        },
        {
          label: '业务部(提成费用池)',
          value: 3
        },
        {
          label: '客服部(提成费用池)',
          value: 4
        },
        {
          label: '业务经理(提成费用池)',
          value: 5
        },
        {
          label: '客服经理(提成费用池)',
          value: 6
        }
      ],
      dataRule: {
        payOrderType: [
          { required: true, message: '请选择付款单类型', trigger: 'change' }
        ],
        payBasisSource: [
          { required: true, message: '请选择付款依据来源', trigger: 'change' }
        ],
        payObject: [
          { required: true, message: '请选择付款对象', trigger: 'change' }
        ],
        price: [
          { required: true, message: '请输入付款申请金额', trigger: 'blur' }
        ],
        motorcadeId: [
          { required: true, message: '请选择收款供应商', trigger: 'change' }
        ],
        driverId: [
          { required: true, message: '请选择收款司机', trigger: 'change' }
        ],
        remark: [
          { required: true, message: '请输入付款备注', trigger: 'change' }
        ],
        requestPayAmount: this._Valid.canNullinit(['nullOrPrice'])
      }
    }
  },
  components: {
    AddOrderPopup,
    AddRoyaltyPopup,
    AddSaleRoyaltyPopup,
    AddSaleAdminRoyaltyPopup,
    AddCareRoyaltyPopup,
    AddCareAdminRoyaltyPopup
  },
  methods: {
    payGenInit (costData) {
      this.isDetail = false
      this.visible = true
      this.submitButton = false
      this.initForm()
      this.costData = costData
      this.dataForm.motorcadeId = costData.motorcadeId
      // 设置车队id
      this.findMotorcadeList(costData.motorcadeId)
      this.disableDriver = costData.disableDriver
      this.autoOpen = true
      // 查询司机
      let captain = null
      if (!costData.disableDriver) {
        captain = 1
      }
      this.findDriverList(captain, costData.driverId)
      // 默认订单
      this.dataForm.payBasisSource = costData.payBasisSource
      this.dataForm.payObject = costData.payObject
      this.dataForm.payOrderType = costData.payOrderType
      this.openSelectCostWindow()
    },
    openSelectCostWindow () {
      // 自动打开选择费用数据
      if (this.dataForm.payObject === 1) {
        this.addRoyaltyVisible = true
        this.$nextTick(() => {
          this.$refs.addRoyaltyPopup.autoWindow(this.costData.ids, this.royaltyDataForm.costMap)
        })
      }
      // 打开应付费用
      if (this.dataForm.payObject === 2) {
        this.addOrderVisible = true
        this.$nextTick(() => {
          this.$refs.addOrderPopup.autoWindow(this.costData.ids, this.orderDataForm.costMap)
        })
      }
      // 打开应付费用
      if (this.dataForm.payObject === 3) {
        this.addSaleRoyaltyVisible = true
        this.$nextTick(() => {
          this.$refs.addSaleRoyaltyPopup.autoWindow(this.costData.ids, this.royaltySaleDataForm.costMap)
        })
      }
      // 打开应付费用
      if (this.dataForm.payObject === 4) {
        this.addCareRoyaltyVisible = true
        this.$nextTick(() => {
          this.$refs.addCareRoyaltyPopup.autoWindow(this.costData.ids, this.royaltyCareDataForm.costMap)
        })
      }
      // 打开应付费用 5 业务经理
      if (this.dataForm.payObject === 5) {
        this.addSaleAdminRoyaltyVisible = true
        this.$nextTick(() => {
          this.$refs.addSaleAdminRoyaltyPopup.autoWindow(this.costData.ids, this.royaltySaleAdminDataForm.costMap)
        })
      }
      // 打开应付费用 6 客服经理
      if (this.dataForm.payObject === 6) {
        this.addCareAdminRoyaltyVisible = true
        this.$nextTick(() => {
          this.$refs.addCareAdminRoyaltyPopup.autoWindow(this.costData.ids, this.royaltyCareAdminDataForm.costMap)
        })
      }
    },
    initForm () {
      this.visible = true
      this.submitButton = false
      this.disableDriver = false
      this.autoOpen = false
      this.costData = {}
      this.dataForm = {
        payOrderType: null,
        payBasisSource: null,
        payObject: null,
        requestPayAmount: null,
        motorcadeId: null,
        motorcadeName: null,
        driverId: null,
        driverName: null,
        wxOpenId: null,
        remark: null
      }
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
      })
      this.changeDriver()
      this.driverList = []
    },
    init (id) {
      this.isDetail = id !== undefined && id !== null
      this.initForm()
      if (this.isDetail) {
        this.$http({
          url: this.$http.adornUrl('/payRequest/detail/' + id),
          method: 'get',
          params: this.$http.adornParams()
        }).then(({ data }) => {
          this.dataForm = data
          this.dataForm.motorcadeId = data.motorcadeName
          this.dataForm.driverId = data.driverName
          if (data.payObject === 1) {
            this.royaltyDataForm.dataList = data.basisList
          } else if (data.payObject === 2) {
            this.orderDataForm.dataList = data.basisList
          } else if (data.payObject === 3) {
            this.royaltySaleDataForm.dataList = data.basisList
          } else if (data.payObject === 4) {
            this.royaltyCareDataForm.dataList = data.basisList
          } else if (data.payObject === 5) {
            this.royaltySaleAdminDataForm.dataList = data.basisList
          } else if (data.payObject === 6) {
            this.royaltyCareAdminDataForm.dataList = data.basisList
          }
        })
      } else {
        this.findMotorcadeList()
      }
    },
    findMotorcadeList (motorcadeId) {
      this.$http({
        url: this.$http.adornUrl('/motorcade/findListBySelect'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.motorcadeList = data
        if (motorcadeId) {
          this.dataForm.motorcadeId = motorcadeId
          for (let i = 0; i < this.motorcadeList.length; i++) {
            if (this.motorcadeList[i].id === this.dataForm.motorcadeId) {
              this.dataForm.motorcadeName = this.motorcadeList[i].simpleName
              break
            }
          }
        }
      })
    },
    clearForm () {
      this.orderDataForm.costMap = new Map()
      this.orderDataForm.dataList = []
      this.royaltyDataForm.costMap = new Map()
      this.royaltyDataForm.dataList = []
      this.royaltySaleDataForm.costMap = new Map()
      this.royaltySaleDataForm.dataList = []
      this.royaltySaleAdminDataForm.costMap = new Map()
      this.royaltySaleAdminDataForm.dataList = []
      this.royaltyCareDataForm.costMap = new Map()
      this.royaltyCareDataForm.dataList = []
      this.royaltyCareAdminDataForm.costMap = new Map()
      this.royaltyCareAdminDataForm.dataList = []
    },
    clearDriver () {
      this.dataForm.wxOpenId = null
      this.dataForm.driverName = null
    },
    // 清空金额
    clearRequestPayAmount () {
      this.dataForm.requestPayAmount = null
      this.clearForm()
    },
    findDriverList (captain, driverId) {
      this.dataForm.motorcadeName = null
      this.dataForm.driverId = null
      this.changeDriver()
      this.driverList = []
      if (this.dataForm.payBasisSource === 1) {
        this.dataForm.requestPayAmount = null
      }
      if (!this.dataForm.motorcadeId) {
        return false
      }
      this.$http({
        url: this.$http.adornUrl('/driver/findListByWxOpenId'),
        method: 'get',
        params: this.$http.adornParams({
          motorcadeId: this.dataForm.motorcadeId,
          captain: captain
        })
      }).then(({ data }) => {
        this.driverList = data
        if (driverId !== null) {
          this.dataForm.driverId = driverId
          this.changeDriver()
        }
        if (data.length === 1) {
          this.disableDriver = true
        }
      })
      for (let i = 0; i < this.motorcadeList.length; i++) {
        if (this.motorcadeList[i].id === this.dataForm.motorcadeId) {
          this.dataForm.motorcadeName = this.motorcadeList[i].simpleName
          break
        }
      }
    },
    changeDriver () {
      this.clearDriver()
      this.clearForm()
      if (this.dataForm.payBasisSource === 1) {
        this.dataForm.requestPayAmount = null
      }
      if (this.dataForm.driverId) {
        for (let i = 0; i < this.driverList.length; i++) {
          if (this.driverList[i].id === this.dataForm.driverId) {
            this.dataForm.wxOpenId = this.driverList[i].wxOpenId
            this.dataForm.driverName = this.driverList[i].name
            break
          }
        }
      }
    },
    // 新增订单费用科目
    addOrderFree () {
      if (this.autoOpen) {
        this.openSelectCostWindow()
        return false
      }
      if (!this.dataForm.motorcadeId) {
        this.$message.error('请选择供应商')
        return false
      }
      if (!this.dataForm.driverId) {
        this.$message.error('请选择司机')
        return false
      }
      if (this.dataForm.payObject === 1) {
        this.addRoyaltyVisible = true
        this.$nextTick(() => {
          this.$refs.addRoyaltyPopup.init(this.royaltyDataForm.costMap, this.dataForm.motorcadeId, this.dataForm.driverId)
        })
      }
      if (this.dataForm.payObject === 2) {
        this.addOrderVisible = true
        this.$nextTick(() => {
          this.$refs.addOrderPopup.init(this.orderDataForm.costMap, this.dataForm.motorcadeId, this.dataForm.driverId)
        })
        return false
      }
      if (this.dataForm.payObject === 3) {
        this.addSaleRoyaltyVisible = true
        this.$nextTick(() => {
          this.$refs.addSaleRoyaltyPopup.init(this.royaltySaleDataForm.costMap, this.dataForm.motorcadeId, this.dataForm.driverId)
        })
      }
      if (this.dataForm.payObject === 4) {
        this.addCareRoyaltyVisible = true
        this.$nextTick(() => {
          this.$refs.addCareRoyaltyPopup.init(this.royaltyCareDataForm.costMap, this.dataForm.motorcadeId, this.dataForm.driverId)
        })
        return false
      }
      if (this.dataForm.payObject === 5) {
        this.addSaleAdminRoyaltyVisible = true
        this.$nextTick(() => {
          this.$refs.addSaleAdminRoyaltyPopup.init(this.royaltySaleAdminDataForm.costMap, this.dataForm.motorcadeId, this.dataForm.driverId)
        })
      }
      if (this.dataForm.payObject === 6) {
        this.addCareAdminRoyaltyVisible = true
        this.$nextTick(() => {
          this.$refs.addCareAdminRoyaltyPopup.init(this.royaltyCareAdminDataForm.costMap, this.dataForm.motorcadeId, this.dataForm.driverId)
        })
        return false
      }
    },
    // 显示选择的费用数据
    showSelectCostList (costMap) {
      let form = this.getForm()
      form.costMap = costMap
      form.dataList = []
      this.dataForm.requestPayAmount = 0
      costMap.forEach((item, key) => {
        item.total = this.amountFixed(this.calculateTheTotal(item))
        form.dataList.push(item)
        // 扣款，借支在总计中扣除
        this.dataForm.requestPayAmount += item.total - (item.driverBorrowing || 0) - (item.deductionsFee || 0) -
        (item.accumulationFund || 0) - (item.safetyAward || 0) - (item.oilConsumption || 0) - (item.hydropower || 0) -
          (item.personalIncomeTax || 0) - (item.otherExpenses || 0) - (item.borrowing || 0)
      })
      form.dataList.sort(function (p1, p2) {
        return p1.vehiclePlan.orderTakeTime < p2.vehiclePlan.orderTakeTime ? 1 : -1
      })
      this.dataForm.requestPayAmount = this.amountFixed(this.dataForm.requestPayAmount)
    },
    // 计算费用之和
    calculateTheTotal (row) {
      let total = Number(0)
      for (let key in row) {
        if (key === 'id' || key === 'vehiclePlan' || key === 'total') {
          continue
        }
        // 扣款，借支不计入单行合计
        if (key !== 'driverBorrowing' && key !== 'deductionsFee' &&
          key !== 'accumulationFund' && key !== 'safetyAward' && key !== 'oilConsumption' && key !== 'hydropower' &&
          key !== 'personalIncomeTax' && key !== 'otherExpenses' && key !== 'borrowing') {
          total += Number(row[key])
        }
      }
      return total
    },
    // 金额去无效的0
    amountFixed (amount) {
      let tmp = Number(amount).toFixed(2)
      if (tmp.endsWith('.00')) {
        return tmp.substring(0, tmp.length - 3)
      } else if (tmp.endsWith('0')) {
        return tmp.substring(0, tmp.length - 1)
      }
      return tmp
    },
    getForm () {
      let form
      if (this.dataForm.payObject === 1) {
        form = this.royaltyDataForm
      } else if (this.dataForm.payObject === 2) {
        form = this.orderDataForm
      } else if (this.dataForm.payObject === 3) {
        form = this.royaltySaleDataForm
      } else if (this.dataForm.payObject === 4) {
        form = this.royaltyCareDataForm
      } else if (this.dataForm.payObject === 5) {
        form = this.royaltySaleAdminDataForm
      } else if (this.dataForm.payObject === 6) {
        form = this.royaltyCareAdminDataForm
      }
      return form
    },
    deleteRow (id) {
      let form = this.getForm()
      form.costMap.delete(id)
      this.showSelectCostList(form.costMap)
    },
    // 表单提交
    dataFormSubmit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          if (this.dataForm.payBasisSource === 1) {
            this.dataForm.basisList = this.getForm().dataList
            if (this.dataForm.basisList.length === 0) {
              this.$message.error('请添加订单费用')
              return false
            }
          } else {
            if (this.dataForm.requestPayAmount <= 0) {
              this.$message.error('支付金额不能小于等于0')
              return false
            }
          }
          this.submitButton = true
          this.$http({
            url: this.$http.adornUrl('/payRequest/addPayRequestOrder'),
            method: 'POST',
            data: this.$http.adornData(this.dataForm)
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1000,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error('操作失败')
              this.submitButton = false
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="less">
.title {
  font-size: 18px;
  font-weight: 700;
}

.popup-detail {
  .infoBox {
    // box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px, rgba(0, 0, 0, 0.12) 0px 0px 6px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 3px, rgba(0, 0, 0, 0.08) 0px 0px 3px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
    padding: 10px;
  }

  .el-dialog {
    margin-top: 0 !important;
    top: 45% !important;
    transform: translate(0, -50%);
  }

  .infoBox2 {
    th.yellow-th {
      background: yellow !important;
    }

    th.pink-th {
      background: pink !important;
    }

    td {
      padding: 0 0;
    }
  }
}
</style>
