<!-- 添加提成费用数据弹窗 -->
<template>
  <div id="add-order-detail">
    <el-dialog title="【订单依据】新增"
               :close-on-click-modal="false"
               :show-close="false"
               width="80%"
               :visible.sync="visible">
      <div class="searchBox" v-if="!autoOpen">
        <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
          <el-form-item label="提货时间">
            <el-date-picker
              v-model="takeGoodsTime"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="-"
              size="small"
              value-format="yyyy-MM-dd"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="getDataList"
              :picker-options="$store.state.common.pickerOptions">
            </el-date-picker>
          </el-form-item>
          <!--          <el-form-item label="司机姓名">
                      <el-input v-model="dataForm.driverName" placeholder="支持模糊查询" clearable></el-input>
                    </el-form-item>-->
          <el-form-item label="司机车牌">
            <el-input v-model="dataForm.vehiclePlate" placeholder="支持模糊查询" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="getDataList()" type="primary">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="tableBox">
        <el-table :data="dataList"
                  :header-cell-class-name="cellClass"
                  ref="multipleTable"
                  @row-click="rowClick"
                  :row-style="selectedHighlight"
                  v-loading="dataListLoading"
                  border
                  size="mini"
                  height="65vh"
                  style="width: 100%;">
          <el-table-column type="index" label="序号" align="center" width="35"></el-table-column>
          <el-table-column prop="vehiclePlan.orderTakeTime" label="提货时间" align="center" width="130"></el-table-column>
          <el-table-column prop="vehiclePlan.vehiclePlate" label="司机车牌" align="center" width="90"></el-table-column>
          <el-table-column prop="vehiclePlan.motorcadeName" label="供应商" align="center" width="110"></el-table-column>
          <el-table-column prop="vehiclePlan.driverName" label="司机姓名" align="center" width="80"></el-table-column>
          <el-table-column v-for="(row, index) in tableColumns"
                           :key="index"
                           :prop="row.prop"
                           :label="row.label"
                           :min-width="row.minWidth"
                           align="left">
            <template slot="header" slot-scope="scope">
              <el-checkbox
                class="returnCheck"
                :indeterminate="row.tableIsIndeterminate"
                :scope="scope"
                v-model="row.checkAll"
                @change="handleChange(row)"
                :label="row.label"/>
            </template>
            <template scope="scope">
              <!-- 费用为空不显示内容 -->
              <span v-if="!scope.row[row.prop]"> </span>
              <!-- 显示可勾选 -->
              <el-checkbox v-else-if="configList[scope.$index][row.prop] === 1"
                           v-model="configList[scope.$index][row.propBox]"
                           @change="selectCheckBox(row, scope.row, row.prop, configList[scope.$index][row.propBox])"
                           :label="scope.row[row.prop]"/>
              <!-- 已申请此科目但未付款 -->
              <el-checkbox v-else-if="configList[scope.$index][row.prop] === 2"
                           v-model="configList[scope.$index][row.propBox]"
                           :disabled="true"
                           :label="scope.row[row.prop]"/>
              <!-- 已支付 -->
              <span v-else-if="configList[scope.$index][row.prop] === 3">{{ scope.row[row.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="sizeChangeHandle"
                       @current-change="currentChangeHandle"
                       :current-page="pageIndex"
                       :page-sizes="[20, 50, 100]"
                       :page-size="dataForm.limit"
                       :total="totalPage"
                       v-if="!autoOpen"
                       layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">关闭</el-button>
        <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      autoOpen: false,
      dataForm: {
        page: 1,
        limit: 20,
        vehiclePlate: null,
        motorcadeId: null,
        motorcadeName: null,
        driverId: null,
        driverName: null
      },
      takeGoodsTime: null,
      pageIndex: 1, // 当前页
      totalPage: 0, // 总数
      dataListLoading: false,
      dataList: [],
      configList: [],
      tableColumns: [
        {
          label: '提成',
          prop: 'saleRoyaltyFee',
          propBox: 'saleRoyaltyFeeBox',
          minWidth: 90,
          tableIsIndeterminate: false,
          checkAll: false
        }, {
          label: '装卸费',
          prop: 'loadFee',
          propBox: 'loadFeeBox',
          minWidth: 70,
          tableIsIndeterminate: false,
          checkAll: false
        }, {
          label: '加班费',
          prop: 'extraFee',
          propBox: 'extraFeeBox',
          minWidth: 70,
          tableIsIndeterminate: false,
          checkAll: false
        }, {
          label: '住宿费',
          prop: 'stayFee',
          propBox: 'stayFeeBox',
          minWidth: 70,
          tableIsIndeterminate: false,
          checkAll: false
        }, {
          label: '基本工资',
          prop: 'saleBaseFee',
          propBox: 'saleBaseFeeBox',
          minWidth: 90,
          tableIsIndeterminate: false,
          checkAll: false
        }, {
          label: '绩效工资',
          prop: 'meritsFee',
          propBox: 'meritsFeeBox',
          minWidth: 90,
          tableIsIndeterminate: false,
          checkAll: false
        }, {
          label: '使用APP奖励',
          prop: 'appFee',
          propBox: 'appFeeBox',
          minWidth: 115,
          tableIsIndeterminate: false,
          checkAll: false
        }, {
          label: '租房费',
          prop: 'other1Fee',
          propBox: 'other1FeeBox',
          minWidth: 95,
          tableIsIndeterminate: false,
          checkAll: false
        }, {
          label: '油耗奖励',
          prop: 'other2Fee',
          propBox: 'other2FeeBox',
          minWidth: 95,
          tableIsIndeterminate: false,
          checkAll: false
        }, {
          label: '安全奖',
          prop: 'safetyRewards',
          propBox: 'safetyRewardsBox',
          minWidth: 95,
          tableIsIndeterminate: false,
          checkAll: false
        }, {
          label: '借支',
          prop: 'borrowing',
          propBox: 'borrowingBox',
          minWidth: 90,
          tableIsIndeterminate: false,
          checkAll: false
        }, {
          label: '扣社保',
          prop: 'deductionsFee',
          propBox: 'deductionsFeeBox',
          minWidth: 90,
          tableIsIndeterminate: false,
          checkAll: false
        }, {
          label: '公积金',
          prop: 'accumulationFund',
          propBox: 'accumulationFundBox',
          minWidth: 90,
          tableIsIndeterminate: false,
          checkAll: false
        }, {
          label: '扣减安全奖',
          prop: 'safetyAward',
          propBox: 'safetyAwardBox',
          minWidth: 110,
          tableIsIndeterminate: false,
          checkAll: false
        }, {
          label: '扣减违章/油耗',
          prop: 'oilConsumption',
          propBox: 'oilConsumptionBox',
          minWidth: 120,
          tableIsIndeterminate: false,
          checkAll: false
        }, {
          label: '分摊水电费',
          prop: 'hydropower',
          propBox: 'hydropowerBox',
          minWidth: 110,
          tableIsIndeterminate: false,
          checkAll: false
        }, {
          label: '个税',
          prop: 'personalIncomeTax',
          propBox: 'personalIncomeTaxBox',
          minWidth: 90,
          tableIsIndeterminate: false,
          checkAll: false
        }, {
          label: '其他费用',
          prop: 'otherExpenses',
          propBox: 'otherExpensesBox',
          minWidth: 90,
          tableIsIndeterminate: false,
          checkAll: false
        }
      ],
      // 选中值
      selectBoxMap: new Map(),
      getIndex: 1
    }
  },
  methods: {
    /**
     * 全选功能
     */
    handleChange (row) {
      this.configList.forEach(item => {
        // 只有可选时才设置
        if (item[row.prop] && item[row.prop] === 1) {
          for (let i = 0; i < this.dataList.length; i++) {
            let element = this.dataList[i]
            if (item.costId === element.id && element[row.prop] && element[row.prop] > 0) {
              item[row.propBox] = row.checkAll
              this.changeBoxMap(element, row.prop, row.checkAll)
              break
            }
          }
        }
      })
      row.tableIsIndeterminate = false
    },
    /**
     * 改变选中map集合数据
     */
    changeBoxMap (row, prop, propBox) {
      let select = this.selectBoxMap.get(row.id)
      if (select === undefined || select === null) {
        select = { id: row.id, vehiclePlan: row.vehiclePlan }
        this.selectBoxMap.set(row.id, select)
      }
      if (propBox) {
        select[prop] = row[prop]
      } else {
        delete select[prop]
      }
      delete select['total']
      if (select === undefined || select === null || Object.keys(select).length <= 2) {
        this.selectBoxMap.delete(row.id)
      }
    },
    /**
     * 数据行选中/取消选中
     */
    selectCheckBox (tableRow, row, prop, propBox) {
      this.changeBoxMap(row, prop, propBox)
      let tempList = []
      this.configList.forEach(item => {
        if (item[prop] && item[prop] === 1) {
          for (let i = 0; i < this.dataList.length; i++) {
            let element = this.dataList[i]
            if (item.costId === element.id && element[prop] && element[prop] > 0) {
              tempList.push(item)
              break
            }
          }
        }
      })
      let isAllTrue = tempList.every(
        item => item[tableRow.propBox] === true
      )
      let isAllFalse = tempList.every(
        item => item[tableRow.propBox] === false
      )
      if (isAllTrue) {
        tableRow.tableIsIndeterminate = false
        tableRow.checkAll = true
      } else if (isAllFalse) {
        tableRow.tableIsIndeterminate = false
        tableRow.checkAll = false
      } else {
        tableRow.checkAll = false
        tableRow.tableIsIndeterminate = true
      }
    },
    dataFormSubmit () {
      if (this.selectBoxMap.size === 0) {
        this.$message.error('至少勾选一个')
        return false
      }
      this.$parent.showSelectCostList(this.selectBoxMap)
      this.visible = false
    },
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    cellClass (row) {
      if (row.columnIndex === 0) {
        return 'DisabledSelection'
      }
    },
    /**
     * 点击表格变色end
     */
    init (costMap, motorcadeId, driverId) {
      this.visible = true
      this.autoOpen = false
      this.dataForm.motorcadeId = motorcadeId
      this.dataForm.driverId = driverId
      this.selectBoxMap = costMap
      this.getDataList()
    },
    autoWindow (costIds, costMap) {
      this.visible = true
      this.autoOpen = true
      this.selectBoxMap = costMap
      this.getDataListByCostIds(costIds)
    },
    getDataListByCostIds (costIds) {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/payRequest/findPayableSaleAdminRoyaltyListByIds'),
        method: 'POST',
        data: {
          ids: costIds
        }
      }).then(({ data }) => {
        if (data) {
          this.dataList = data.list
          this.configList = data.configList
          this.totalPage = data.total
          this.selectedValueBeforeSetting()
        } else {
          this.dataList = []
          this.configList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    getDataList () {
      this.dataListLoading = true
      let dataForm = JSON.parse(JSON.stringify(this.dataForm))
      let takeGoodsTime = this.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.beginDate = takeGoodsTime[0]
        timeParam.endDate = takeGoodsTime[1]
      }
      dataForm.beginDate = timeParam.beginDate
      dataForm.endDate = timeParam.endDate
      this.$http({
        url: this.$http.adornUrl('/payRequest/findPayableSaleRoyaltyCostList'),
        method: 'get',
        params: this.$http.adornParams(dataForm)
      }).then(({ data }) => {
        if (data) {
          this.dataList = data.list
          this.configList = data.configList
          this.totalPage = data.total
          this.selectedValueBeforeSetting()
        } else {
          this.dataList = []
          this.configList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 设置选中之前选中过的值
    selectedValueBeforeSetting () {
      if (this.selectBoxMap.size === 0 || this.configList.length === 0) {
        return false
      }
      for (let i = 0; i < this.configList.length; i++) {
        let element = this.configList[i]
        let select = this.selectBoxMap.get(element.costId)
        if (select === null || select === undefined) {
          continue
        }
        for (let prop in select) {
          if (prop === 'id' || prop === 'vehiclePlan' || prop === 'total') {
            continue
          }
          element[prop + 'Box'] = true
        }
      }
    },
    // 每页数
    sizeChangeHandle (val) {
      this.dataForm.limit = val
      this.dataForm.page = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.dataForm.page = val
      this.getDataList()
    }
  }
}
</script>
<style lang="less">
#add-order-detail {
  .el-dialog {
    margin-top: 0 !important;
    top: 50%;
    transform: translate(0, -50%);
  }
  .el-dialog__header {
    padding: 10px 0px;
  }
  .el-dialog__body {
    padding: 0px 10px;
    .el-table .DisabledSelection .cell .el-checkbox {
      display: none;
      position: relative
    }
    // .el-table .DisabledSelection .cell:before {
    //   content: '选择';
    //   position: absolute;
    // }
  }
  .el-pagination {
    margin-top: 5px;
    span {
      font-size: 12px;
    }
    .el-input__inner {
      height: 22px !important;
    }
  }
  .el-dialog__footer {
    padding: 10px 10px;
    button.el-button {
      height: 26px;
      line-height: 0;
    }
  }

  .el-checkbox__label {
    padding-left: 5px;
  }
}
</style>
